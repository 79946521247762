import axios from 'axios'

const service = axios.create({
    // timeout: 30000,
    // headers: {
    //     'Content-Type': 'application/json',
    // },
    // headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded',
    // },
})

service.interceptors.request.use(
    (config) => {
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    (response) => {
        let res = response.data
        if (res.code === 200) {
            return res.result
        } else {
            // console.log(res.message)
            return Promise.reject(new Error(res.message || 'Error'))
        }
    },
    (error) => {
        return Promise.reject(error)
    }
)

export default service
