import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// const originalPush = VueRouter.prototype.push
// //修改原型对象中的push方法
// VueRouter.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch((err) => err)
// }

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('../views/index.vue'),
    },
    {
        path: '/article/list/:id',
        name: 'list',
        component: () => import('../views/article_list.vue'),
    },
    {
        path: '/article/info/:id',
        name: 'article',
        component: () => import('../views/article_info.vue'),
    },
    {
        path: '*',
        name: '404',
        redirect: '/',
    },
]

const router = new VueRouter({
    routes,
})

export default router
